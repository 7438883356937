import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import mitt from 'mitt';
import { RUNTIME_PROP_NODE_ID, RUNTIME_PROP_SLOTS } from './constants.js';
const ResetNodeErrorsKeyContext = React.createContext(0);
export const ResetNodeErrorsKeyProvider = ResetNodeErrorsKeyContext.Provider;
export const ComponentsContext = React.createContext(null);
export const NodeRuntimeContext = React.createContext(null);
export const CanvasEventsContext = React.createContext(mitt());
function SlotsWrapper({ children }) {
    return React.createElement(React.Fragment, null, children);
}
// We want typescript to enforce these props, even when they're not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PlaceholderWrapper(props) {
    return (React.createElement("div", { style: {
            minHeight: 72,
            minWidth: 200,
        } }));
}
// We will use [RUNTIME_PROP_NODE_ID] while walking the fibers to detect React Elements that
// represent AppDomNodes. We use a wrapper to ensure only one element exists in the React tree
// that has [RUNTIME_PROP_NODE_ID] property with this nodeId (We could clone the child and add
// the prop, but the child may be spreading its props to other elements). We also don't want this
// property to end up on DOM nodes.
// IMPORTANT! This node must directly wrap the React Element for the AppDomNode
function NodeFiberHost({ children }) {
    return children;
}
export function NodeRuntimeWrapper({ nodeId, componentConfig, children, NodeError, }) {
    const resetNodeErrorsKey = React.useContext(ResetNodeErrorsKeyContext);
    const ErrorFallback = React.useCallback(({ error }) => (React.createElement(NodeFiberHost, { ...{
            [RUNTIME_PROP_NODE_ID]: nodeId,
            nodeError: error,
            componentConfig,
        } },
        React.createElement(NodeError, { error: error }))), [NodeError, componentConfig, nodeId]);
    return (React.createElement(ErrorBoundary, { resetKeys: [resetNodeErrorsKey], fallbackRender: ErrorFallback },
        React.createElement(NodeRuntimeContext.Provider, { value: nodeId },
            React.createElement(NodeFiberHost, { ...{
                    [RUNTIME_PROP_NODE_ID]: nodeId,
                    componentConfig,
                } }, children))));
}
export function useNode() {
    const nodeId = React.useContext(NodeRuntimeContext);
    const canvasEvents = React.useContext(CanvasEventsContext);
    return React.useMemo(() => {
        if (!nodeId) {
            return null;
        }
        return {
            updateAppDomConstProp: (prop, value) => {
                canvasEvents.emit('propUpdated', {
                    nodeId,
                    prop,
                    value,
                });
            },
        };
    }, [canvasEvents, nodeId]);
}
export function Placeholder({ prop, children, hasLayout = false }) {
    const nodeId = React.useContext(NodeRuntimeContext);
    if (!nodeId) {
        return React.createElement(React.Fragment, null, children);
    }
    const count = React.Children.count(children);
    return count > 0 ? (React.createElement(React.Fragment, null, children)) : (React.createElement(PlaceholderWrapper, { parentId: nodeId, ...{
            [RUNTIME_PROP_SLOTS]: prop,
            slotType: hasLayout ? 'layout' : 'single',
        } }));
}
export function Slots({ prop, children }) {
    const nodeId = React.useContext(NodeRuntimeContext);
    if (!nodeId) {
        return React.createElement(React.Fragment, null, children);
    }
    const count = React.Children.count(children);
    return count > 0 ? (React.createElement(SlotsWrapper, { parentId: nodeId, ...{
            [RUNTIME_PROP_SLOTS]: prop,
            slotType: 'multiple',
        } }, children)) : (React.createElement(Placeholder, { prop: prop }));
}

import * as React from 'react';
import { createComponent } from '@mui/toolpad-core';
import { Box, List as MuiList, ListItem } from '@mui/material';
import { SX_PROP_HELPER_TEXT } from './constants';
function List({ itemCount, renderItem, disablePadding = false, sx }) {
    return (React.createElement(MuiList, { disablePadding: disablePadding, sx: { width: '100%', ...sx } }, [...Array(itemCount).keys()].map((index) => (React.createElement(ListItem, { key: index, disablePadding: disablePadding },
        React.createElement(Box, { sx: { width: '100%', p: 0, m: 0 } }, renderItem({ i: index })))))));
}
export default createComponent(List, {
    argTypes: {
        itemCount: {
            helperText: 'Number of items to render.',
            typeDef: { type: 'number', default: 3 },
        },
        renderItem: {
            typeDef: { type: 'template' },
            control: { type: 'layoutSlot' },
        },
        disablePadding: {
            helperText: 'If true, vertical padding is removed from the list.',
            typeDef: { type: 'boolean' },
        },
        sx: {
            helperText: SX_PROP_HELPER_TEXT,
            typeDef: { type: 'object' },
        },
    },
});

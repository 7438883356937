import * as React from 'react';
/**
 * Consume a context but throw when used outside of a provider.
 */
export function useNonNullableContext(context, name) {
    const maybeContext = React.useContext(context);
    if (maybeContext === null || maybeContext === undefined) {
        throw new Error(`context "${name}" was used without a Provider`);
    }
    return maybeContext;
}
/**
 * Context that throws when used outside of a provider.
 */
export function createProvidedContext(name) {
    const context = React.createContext(undefined);
    const useContext = () => useNonNullableContext(context, name);
    return [useContext, context.Provider];
}

import * as React from 'react';
import { Box } from '@mui/material';
import { createComponent } from '@mui/toolpad-core';
function PageRow({ layoutColumnSizes = [], gap, children }) {
    const gridAutoColumns = layoutColumnSizes.reduce((acc, layoutColumnSize) => `${acc}${`${acc && ' '}minmax(0, ${layoutColumnSize || 1}fr)`}`, '');
    return (React.createElement(Box, { sx: {
            gap,
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns,
        } }, children));
}
export default createComponent(PageRow, {
    argTypes: {
        gap: {
            typeDef: { type: 'number', default: 1, minimum: 1 },
        },
        children: {
            typeDef: { type: 'element' },
            control: { type: 'slots' },
        },
    },
});

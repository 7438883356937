/**
 * Makes the first letter of [str] uppercase.
 * Not locale aware.
 */
export function uncapitalize(str) {
    return str.length > 0 ? str[0].toLowerCase() + str.slice(1) : '';
}
/**
 * Makes the first letter of [str] lowercase.
 * Not locale aware.
 */
export function capitalize(str) {
    return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';
}
/**
 * Joins all [parts] and camelcases the result
 */
export function camelCase(...parts) {
    if (parts.length > 0) {
        const [first, ...rest] = parts;
        return uncapitalize(first) + rest.map((part) => capitalize(part)).join('');
    }
    return '';
}
/**
 * Generates a string for `base` by add a number until it's unique amongst a set of predefined names.
 */
export function generateUniqueString(base, existingNames) {
    let i = 1;
    if (!existingNames.has(base)) {
        return base;
    }
    const newBase = base.replace(/\d+$/, '');
    let suggestion = newBase;
    while (existingNames.has(suggestion)) {
        suggestion = newBase + String(i);
        i += 1;
    }
    return suggestion;
}
/**
 * Escape string for use in HTML.
 */
export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}
/**
 * Normalizes and removes all diacritics from a javascript string.
 *
 * See https://stackoverflow.com/a/37511463
 */
export function removeDiacritics(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
export function isAbsoluteUrl(maybeUrl) {
    try {
        return !!new URL(maybeUrl);
    }
    catch {
        return false;
    }
}
/**
 * Removes a prefix from a string if it starts with it.
 */
export function removePrefix(input, prefix) {
    return input.startsWith(prefix) ? input.slice(prefix.length) : input;
}
/**
 * Removes a suffix from a string if it ends with it.
 */
export function removeSuffix(input, suffix) {
    return input.endsWith(suffix) ? input.slice(0, input.length - suffix.length) : input;
}
/**
 * Adds a prefix to a string if it doesn't start with it.
 */
export function ensurePrefix(input, prefix) {
    return input.startsWith(prefix) ? input : prefix + input;
}
/**
 * Adds a suffix to a string if it doesn't end with it.
 */
export function ensureSuffix(input, suffix) {
    return input.endsWith(suffix) ? input : input + suffix;
}
/**
 * Regex to statically find all static import statements
 *
 * Tested against:
 *   import {
 *     Component
 *   } from '@angular2/core';
 *   import defaultMember from "module-name";
 *   import   *    as name from "module-name  ";
 *   import   {  member }   from "  module-name";
 *   import { member as alias } from "module-name";
 *   import { member1 ,
 *   member2 } from "module-name";
 *   import { member1 , member2 as alias2 , member3 as alias3 } from "module-name";
 *   import defaultMember, { member, member } from "module-name";
 *   import defaultMember, * as name from "module-name";
 *   import "module-name";
 *   import * from './smdn';
 */
const IMPORT_STATEMENT_REGEX = /^\s*import(?:["'\s]*([\w*{}\n, ]+)from\s*)?["'\s]*([^"']+)["'\s].*/gm;
/**
 * Statically analyses a javascript source code for import statements and return the specifiers.
 *
 * NOTE: This function does a best effort without parsing the code. The result may contain false
 *       positives
 */
export function findImports(src) {
    return Array.from(src.matchAll(IMPORT_STATEMENT_REGEX), (match) => match[2]);
}
/**
 * Limits the length of a string and adds ellipsis if necessary.
 */
export function truncate(str, maxLength, dots = '...') {
    if (str.length <= maxLength) {
        return str;
    }
    return str.slice(0, maxLength) + dots;
}
/**
 * Prepend a prefix to each line in the text
 */
export function prependLines(text, prefix) {
    return text
        .split('\n')
        .map((line) => prefix + line)
        .join('\n');
}
/**
 * Indent the text with [length] number of spaces
 */
export function indent(text, length) {
    return prependLines(text, ' '.repeat(length));
}

export function asArray(maybeArray) {
    return Array.isArray(maybeArray) ? maybeArray : [maybeArray];
}
/**
 * Type aware version of Object.protoype.hasOwnProperty.
 * See https://fettblog.eu/typescript-hasownproperty/
 */
export function hasOwnProperty(obj, prop) {
    return obj.hasOwnProperty(prop);
}
export function mapProperties(obj, mapper) {
    return Object.fromEntries(Object.entries(obj).flatMap((entry) => {
        const mapped = mapper(entry);
        return mapped ? [mapped] : [];
    }));
}
/**
 * Maps an objects' property keys. The result is a new object with the mapped keys, but the same values.
 */
export function mapKeys(obj, mapper) {
    return mapProperties(obj, ([key, value]) => [mapper(key), value]);
}
/**
 * Maps an objects' property values. The result is a new object with the same keys, but mapped values.
 */
export function mapValues(obj, mapper) {
    return mapProperties(obj, ([key, value]) => [key, mapper(value, key)]);
}
export function filterValues(obj, filter) {
    return mapProperties(obj, ([key, value]) => (filter(value) ? [key, value] : null));
}
export function filterKeys(obj, filter) {
    return mapProperties(obj, ([key, value]) => (filter(key) ? [key, value] : null));
}

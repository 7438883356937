import * as React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { createComponent } from '@mui/toolpad-core';
const readFile = async (file) => {
    return new Promise((resolve, reject) => {
        const readerBase64 = new FileReader();
        readerBase64.onload = (event) => {
            if (!event.target) {
                reject();
                return;
            }
            resolve(event.target.result);
        };
        readerBase64.readAsDataURL(file);
    });
};
function FilePicker({ multiple, onChange, ...props }) {
    const handleChange = async (changeEvent) => {
        const filesPromises = Array.from(changeEvent.target.files || []).map(async (file) => {
            const fullFile = {
                name: file.name,
                type: file.type,
                size: file.size,
                base64: await readFile(file),
            };
            return fullFile;
        });
        const files = await Promise.all(filesPromises);
        onChange(files);
    };
    return (React.createElement(MuiTextField, { ...props, type: "file", value: undefined, inputProps: { multiple }, onChange: handleChange, InputLabelProps: { shrink: true } }));
}
export default createComponent(FilePicker, {
    helperText: 'File picker component.\nIt allows users to take select and read files.',
    argTypes: {
        value: {
            typeDef: { type: 'object' },
            visible: false,
            onChangeProp: 'onChange',
        },
        label: {
            helperText: 'A label that describes the content of the FilePicker. e.g. "Profile Image".',
            typeDef: { type: 'string' },
        },
        multiple: {
            helperText: 'Whether the FilePicker should accept multiple files.',
            typeDef: { type: 'boolean', default: true },
        },
        disabled: {
            helperText: 'Whether the FilePicker is disabled.',
            typeDef: { type: 'boolean' },
        },
        sx: {
            typeDef: { type: 'object' },
        },
    },
});

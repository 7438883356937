import * as React from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createComponent } from '@mui/toolpad-core';
import { SX_PROP_HELPER_TEXT } from './constants';
const LOCALE_LOADERS = new Map([
    ['en', () => import('dayjs/locale/en')],
    ['nl', () => import('dayjs/locale/nl')],
    ['fr', () => import('dayjs/locale/fr')],
    // TODO...
]);
const handlers = new Set();
let loadedLocale;
function trygetLoadableLocale(locale) {
    const load = LOCALE_LOADERS.get(locale);
    if (load) {
        return { locale, load };
    }
    return null;
}
function getLoadableLocale() {
    if (typeof window === 'undefined') {
        return null;
    }
    const languages = window.navigator.languages;
    for (const locale of languages) {
        const { language } = new Intl.Locale(locale);
        const result = trygetLoadableLocale(locale) || trygetLoadableLocale(language);
        if (result) {
            return result;
        }
    }
    return null;
}
const loadableLocale = getLoadableLocale();
if (loadableLocale) {
    loadableLocale.load().then(() => {
        loadedLocale = loadableLocale.locale;
        handlers.forEach((handler) => handler());
    });
}
function subscribeLocaleLoader(cb) {
    handlers.add(cb);
    return () => handlers.delete(cb);
}
function getSnapshot() {
    return loadedLocale;
}
function DatePicker({ format, onChange, ...props }) {
    const handleChange = React.useCallback((value) => {
        // date-only form of ISO8601. See https://tc39.es/ecma262/#sec-date-time-string-format
        const stringValue = value?.format('YYYY-MM-DD') || '';
        onChange(stringValue);
    }, [onChange]);
    const adapterLocale = React.useSyncExternalStore(subscribeLocaleLoader, getSnapshot);
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: adapterLocale },
        React.createElement(DesktopDatePicker, { ...props, inputFormat: format || 'L', onChange: handleChange, renderInput: (params) => (React.createElement(TextField, { ...params, fullWidth: props.fullWidth, variant: props.variant, size: props.size, sx: props.sx })) })));
}
export default createComponent(DatePicker, {
    helperText: 'The MUI X [Date picker](https://mui.com/x/react-date-pickers/date-picker/) component.\n\nThe date picker lets the user select a date.',
    argTypes: {
        value: {
            helperText: 'The currently selected date.',
            typeDef: { type: 'string', default: '' },
            onChangeProp: 'onChange',
            defaultValueProp: 'defaultValue',
        },
        format: {
            helperText: 'The [format](https://day.js.org/docs/en/display/format) of the date in the UI. The value for the bindings will always be in the `YYYY-MM-DD` format. Leave empty to let the end-user locale define the format.',
            typeDef: {
                type: 'string',
                default: '',
            },
        },
        defaultValue: {
            helperText: 'A default value for the date picker.',
            typeDef: { type: 'string', default: '' },
        },
        label: {
            helperText: 'A label that describes the content of the date picker. e.g. "Arrival date".',
            typeDef: { type: 'string' },
        },
        variant: {
            helperText: 'One of the available MUI TextField [variants](https://mui.com/material-ui/react-button/#basic-button). Possible values are `outlined`, `filled` or `standard`',
            typeDef: { type: 'string', enum: ['outlined', 'filled', 'standard'], default: 'outlined' },
        },
        size: {
            helperText: 'The size of the component. One of `small`, or `medium`.',
            typeDef: { type: 'string', enum: ['small', 'medium'], default: 'small' },
        },
        fullWidth: {
            helperText: 'Whether the button should occupy all available horizontal space.',
            typeDef: { type: 'boolean' },
        },
        disabled: {
            helperText: 'The date picker is disabled.',
            typeDef: { type: 'boolean' },
        },
        sx: {
            helperText: SX_PROP_HELPER_TEXT,
            typeDef: { type: 'object' },
        },
    },
});

import { TOOLPAD_LOADING_MARKER } from './jsRuntime.js';
import { errorFrom } from './utils/errors.js';
function createBrowserRuntime() {
    let iframe;
    function evalCode(code, globalScope) {
        if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
            iframe.style.display = 'none';
            document.documentElement.appendChild(iframe);
        }
        // eslint-disable-next-line no-underscore-dangle
        iframe.contentWindow.__SCOPE = globalScope;
        iframe.contentWindow.console = window.console;
        return iframe.contentWindow.eval(`
      (() => {
        with (window.__SCOPE) { 
          return (${code})
        }
      })()
    `);
    }
    function evaluateExpression(code, globalScope) {
        try {
            const value = evalCode(code, globalScope);
            return { value };
        }
        catch (rawError) {
            const error = errorFrom(rawError);
            if (error?.message === TOOLPAD_LOADING_MARKER) {
                return { loading: true };
            }
            return { error: error };
        }
    }
    return {
        evaluateExpression,
    };
}
const browserRuntime = typeof window === 'undefined' ? null : createBrowserRuntime();
export function getBrowserRuntime() {
    if (!browserRuntime) {
        throw new Error(`Can't use browser JS runtime outside of a browser`);
    }
    return browserRuntime;
}
export function useBrowserJsRuntime() {
    return getBrowserRuntime();
}

export const TOOLPAD_LOADING_MARKER = '__TOOLPAD_LOADING_MARKER__';
export function evaluateBindable(ctx, bindable, globalScope) {
    if (bindable?.type === 'jsExpression') {
        return ctx.evaluateExpression(bindable?.value, globalScope);
    }
    if (bindable?.type === 'const') {
        return { value: bindable?.value };
    }
    return { value: undefined };
}
